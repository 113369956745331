import {gsap}          from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger.js';

function scrollInit() {
	function gridSetting(size) {
		return {
			scrollTrigger: {
				trigger: '.widget-preview-grid',
				start  : '0px 90%',
				end    : 'bottom top',
				scrub  : 3
			},
			ease         : 'none',
			y            : size
		}
	}

	gsap.registerPlugin(ScrollTrigger);

	gsap.to('.widget-preview-grid', {
		scrollTrigger: {
			trigger: '.widget-preview-grid',
			start  : '0px 90%',
			end    : 'bottom 70%',
			scrub  : 1.5
		},
		width        : '100%'
	});

	gsap.to('.widget-preview-grid__inner--odd', gridSetting('10%'));
	gsap.to('.widget-preview-grid__inner--even', gridSetting('-10%'));
}

document.addEventListener('DOMContentLoaded', function (event) {
	scrollInit();
});

